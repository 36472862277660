<template>
  <div>
    <template>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-card outlined>
            <v-card-subtitle v-if="timeSheetLocal.type === 'otherTime'"
              ><v-icon left small class="mr-2">mdi-card-text</v-icon
              >Allgemein</v-card-subtitle
            >
            <v-card-text v-if="timeSheetLocal.type === 'otherTime'">
              <v-row>
                <v-col cols="12">
                  <span
                    v-if="
                      timeSheetTitleTemplates.length &&
                      timeSheetLocal.type === 'otherTime'
                    "
                    >Vorschläge:</span
                  >
                  <v-chip-group
                    v-if="
                      timeSheetTitleTemplates.length &&
                      timeSheetLocal.type === 'otherTime'
                    "
                    v-model="timeSheetLocal.title"
                    active-class="primary--text"
                    show-arrows
                  >
                    <v-chip
                      v-for="(item, index) in timeSheetTitleTemplates"
                      :key="index"
                      label
                      :value="item"
                      class="transparent-background font-weight-medium mr-2 mb-3"
                      >{{ item }}
                    </v-chip>
                  </v-chip-group>
                  <v-text-field
                    v-if="timeSheetLocal.type === 'otherTime'"
                    v-model="timeSheetLocal.title"
                    outlined
                    hide-details="auto"
                    label="Kurzer Tätigkeitstitel"
                    :disabled="!timeSheetLocal.type === 'otherTime'"
                    :rules="[rules.requiredIfOther, rules.max20Chars]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider v-if="timeSheetLocal.type === 'otherTime'"></v-divider>
            <v-card-subtitle
              ><v-icon left small class="mr-2">mdi-card-text</v-icon>Geleistete
              Zeit</v-card-subtitle
            >
            <v-card-text>
              <v-row>
                <v-col cols="auto">
                  <v-btn depressed @click="setNow('start')" disabled
                    ><v-icon left>mdi-clock-in</v-icon>Jetzt einsetzen</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="timeSheetLocal.workedDuration.start.date"
                    type="date"
                    label="Startdatum"
                    hint="DD.MM.YYYY"
                    outlined
                    hide-details="auto"
                    :rules="[rules.required]"
                    required
                    prepend-inner-icon="mdi-calendar-start"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="timeSheetLocal.workedDuration.start.time"
                    label="Startzeit"
                    hint="HH:MM"
                    :rules="[rules.required]"
                    outlined
                    hide-details="auto"
                    type="time"
                    prepend-inner-icon="mdi-clock-start"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <v-btn depressed @click="setNow('end')" disabled
                    ><v-icon left>mdi-clock-in</v-icon>Jetzt einsetzen</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="timeSheetLocal.workedDuration.end.date"
                    type="date"
                    label="Enddatum"
                    hint="DD.MM.YYYY"
                    outlined
                    hide-details="auto"
                    :rules="[rules.required]"
                    required
                    prepend-inner-icon="mdi-calendar-end"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="timeSheetLocal.workedDuration.end.time"
                    label="Endzeit"
                    hint="HH:MM"
                    hide-details="auto"
                    outlined
                    :rules="[rules.required]"
                    type="time"
                    prepend-inner-icon="mdi-clock-end"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-subtitle
              ><v-icon left small class="mr-2">mdi-card-text</v-icon
              >Abrechenbare Zeit</v-card-subtitle
            >
            <v-card-text>
              <v-row>
                <v-col cols="auto">
                  <v-btn depressed @click="setNow('start')" disabled
                    ><v-icon left>mdi-clock-in</v-icon>Jetzt einsetzen</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="timeSheetLocal.billableDuration.start.date"
                    type="date"
                    label="Startdatum"
                    hint="DD.MM.YYYY"
                    outlined
                    hide-details="auto"
                    :rules="[rules.required]"
                    required
                    prepend-inner-icon="mdi-calendar-start"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="timeSheetLocal.billableDuration.start.time"
                    label="Startzeit"
                    hint="HH:MM"
                    :rules="[rules.required]"
                    outlined
                    hide-details="auto"
                    type="time"
                    prepend-inner-icon="mdi-clock-start"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <v-btn depressed @click="setNow('end')" disabled
                    ><v-icon left>mdi-clock-in</v-icon>Jetzt einsetzen</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="timeSheetLocal.billableDuration.end.date"
                    type="date"
                    label="Enddatum"
                    hint="DD.MM.YYYY"
                    outlined
                    hide-details="auto"
                    :rules="[rules.required]"
                    required
                    prepend-inner-icon="mdi-calendar-end"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="timeSheetLocal.billableDuration.end.time"
                    label="Endzeit"
                    hint="HH:MM"
                    hide-details="auto"
                    outlined
                    :rules="[rules.required]"
                    type="time"
                    prepend-inner-icon="mdi-clock-end"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-subtitle v-if="timeSheetLocal.type === 'dutyTime'"
              ><v-icon left small class="mr-2">mdi-fire-truck</v-icon
              >Einsatzdienst</v-card-subtitle
            >
            <v-card-text v-if="timeSheetLocal.type === 'dutyTime'">
              <v-row>
                <!-- DUTY -->
                <v-col cols="12" sm="6" md="6">
                  <v-radio-group
                    v-model="
                      timeSheetLocal.additionalData.location.isOwnStation
                    "
                    label="Standort"
                    mandatory
                    hide-details="auto"
                    class="mt-0 pt-0"
                    :row="$vuetify.breakpoint.xs ? false : true"
                  >
                    <v-radio label="Eigener Standort" :value="true"></v-radio>
                    <v-radio label="Fremder Standort" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col
                  v-if="!timeSheetLocal.additionalData.location.isOwnStation"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model.trim="timeSheetLocal.additionalData.location.name"
                    outlined
                    hide-details="auto"
                    label="Standortbezeichnung"
                    persistent-hint
                    hint="Bspw. FW 9900, FF 9901"
                    prepend-inner-icon="mdi-map-marker"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-if="timeSheetLocal.additionalData.location.isOwnStation"
                    v-model="
                      timeSheetLocal.additionalData.assignedPosition.unit
                    "
                    :items="units"
                    outlined
                    hide-details="auto"
                    item-text="title"
                    item-value="id"
                    return-object
                    label="Einsatzmittel"
                    prepend-inner-icon="mdi-fire-truck"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-text-field
                    v-else
                    v-model="
                      timeSheetLocal.additionalData.assignedPosition.unit.title
                    "
                    outlined
                    hide-details="auto"
                    label="Einsatzmittel"
                    :rules="[rules.required]"
                    persistent-hint
                    hint="Bspw. LHF 9901/3, RTW 9900/1"
                    prepend-inner-icon="mdi-fire-truck"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="
                      timeSheetLocal.additionalData.assignedPosition.position
                    "
                    :items="positions"
                    outlined
                    hide-details="auto"
                    item-text="title"
                    item-value="id"
                    return-object
                    label="Funktion"
                    prepend-inner-icon="mdi-tag"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-subtitle v-if="timeSheetLocal.type === 'courseTime'"
              ><v-icon left small class="mr-2">mdi-school</v-icon
              >Lehrgang/Fortbildung</v-card-subtitle
            >
            <v-card-text v-if="timeSheetLocal.type === 'courseTime'">
              <v-row>
                <!-- COURSES -->
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="timeSheetLocal.additionalData.course"
                    label="Lehrgang"
                    :items="availableCourses"
                    item-text="title"
                    return-object
                    clearable
                    hint="Lehrgang aus Liste wählen"
                    persistent-hint
                    no-data-text="Keine Lehrgänge vorhanden. Lehrgang manuell im Feld 'Externe Bezeichung' eintragen"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="timeSheetLocal.additionalData.externalName"
                    label="Externe Bezeichnung"
                    hint="z.B. Kurs-/Lehrgangsbezeichnung"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model.trim="timeSheetLocal.additionalData.externalId"
                    label="Kurs-/Lehrgangsnummer"
                    :rules="[rules.required]"
                    hide-details="auto"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="timeSheetLocal.additionalData.proofDate"
                    label="Nachweisdatum"
                    type="date"
                    hint="Datum des Leistungsnachweises/Urkunde"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="timeSheetLocal.additionalData.rating"
                    label="Bewertung"
                    :items="coursesRatingOptions"
                    :rules="[rules.required]"
                    type="date"
                    hide-details="auto"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Nachweise
                  <strong>drop-in</strong>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="timeSheetLocal.description"
                    label="Hinweise/Bemerkungen"
                    hide-details="auto"
                    outlined
                    :rows="3"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
// import { AVAILABILITY_LEAVES_manage_all } from "@/data/permission-types.js";
export default {
  name: "time-sheet-editor",
  props: {
    timeSheet: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        startBeforeEnd: () =>
          (!this.fieldsAreFilled &&
            this.convertToTimestamp(
              this.timeSheetLocal.start.date,
              this.timeSheetLocal.start.time
            ).valueOf() <
              this.convertToTimestamp(
                this.timeSheetLocal.end.date,
                this.timeSheetLocal.end.time
              ).valueOf()) ||
          "Start muss vor Ende liegen",
      },
    };
  },
  model: {
    prop: "timeSheet",
    event: "timeSheetChange",
  },
  computed: {
    timeSheetLocal: {
      get: function () {
        return this.timeSheet;
      },
      set: function (value) {
        this.$emit("timeSheetChange", value);
      },
    },
    timeSheetTitleTemplates() {
      return this.$store.state.organization.activeOrganization.config.accounting
        .timeSheets.titleTemplates;
    },
    payrollTypes() {
      return [
        {
          id: "1",
          title: "Stundenlohn",
          disabled: false,
          formula: "{{ SUMME_ANZAHL_STUNDEN }} * 3,50",
        },
        {
          id: "2",
          title: "Pauschale",
          disabled: false,
          formula: "{{ SUMME_ANZAHL_TAGE }} * 8,00",
        },
        {
          id: "2",
          title: "Pauschale WeL",
          disabled: true,
          formula: "{{ SUMME_ANZAHL_MONAT }} * 12,00",
        },
      ];
      // return this.$store.state.accounting.payrollTypes;
    },
    memberships() {
      var result = this.$store.state.admin.membershipsPrivate.map((item) => {
        const container = {
          displayName: item.user.displayName,
          uid: item.user.uid,
        };
        return container;
      });
      return result;
    },
    positions() {
      return [...this.$store.state.availability.positions]
        .sort((a, b) => a.priority - b.priority)
        .map((position) => ({
          title: position.title,
          shortTitle: position.shortTitle,
          id: position.meta.id,
        }));
    },
    units() {
      return [...this.$store.state.availability.units]
        .sort((a, b) => a.priority - b.priority)
        .map((unit) => ({
          title: unit.title,
          id: unit.meta.id,
        }));
    },
    availableCourses() {
      return [...this.$store.state.memberManagement.courses].map((course) => ({
        title: course.title,
        id: course.meta.id,
      }));
    },
    permissionToManageLeaves() {
      // return this.$store.getters["organization/checkPermissionByID"](
      //   `${AVAILABILITY_LEAVES_manage_all}`
      // );
      return true;
    },
    fieldsAreFilled() {
      if (
        !this.leave.period.start.date === "" &&
        !this.leave.period.start.time === "" &&
        !this.leave.period.end.date === "" &&
        !this.leave.period.end.time === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>
