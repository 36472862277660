<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    <v-card v-else>
      <time-sheet-details-card-content
        :timeSheet="timeSheet"
      ></time-sheet-details-card-content>
    </v-card>
  </div>
</template>

<script>
import TimeSheetDetailsCardContent from "@/components/accounting/time-sheets/TimeSheetDetailsCardContent.vue";
export default {
  name: "time-sheet-details-card",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    timeSheet: {
      type: Object,
    },
  },
  components: { TimeSheetDetailsCardContent },
  methods: {},
  computed: {},
};
</script>
