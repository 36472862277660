<template>
  <data-chip
    :text="statusText(type)"
    :color="statusColor(type)"
    :iconLeft="statusIcon(type)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "time-sheet-status-chip",
  components: {
    DataChip,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusText(statusId) {
      switch (statusId) {
        case "dutyTime":
          return "Einsatzstunden";
        case "courseTime":
          return "Lehrgang/Fortbildung";
        case "dutyReport":
          return "Dienstbericht";
        case "otherTime":
          return "Sonstige Stunden";
        default:
          return "Fehler";
      }
    },
    statusIcon(statusId) {
      switch (statusId) {
        case "dutyTime":
          return "fire-truck";
        case "courseTime":
          return "school";
        case "dutyReport":
          return "file-document";
        case "otherTime":
          return "dots-horizontal-circle";
        default:
          return "alert";
      }
    },
    statusColor(statusId) {
      switch (statusId) {
        case "dutyTime":
          return "red";
        case "courseTime":
          return "teal";
        case "dutyReport":
          return "indigo";
        case "otherTime":
          return "blue-grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
